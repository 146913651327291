body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  position: fixed;
  inset: 0;
  display: grid;
  place-items: center;
}

* {
  box-sizing: border-box;
}

@media (max-width: 768px) {
  body {
    height: initial;
    overflow-y: auto;
  }
}

summary::-webkit-details-marker {
  display: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  /*width: calc(100% - 200px);*/
  width: 100%;
  gap: 6px;
}
.my-masonry-grid_column {
  background-clip: padding-box;
}
